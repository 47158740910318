/* This section styles the entire Navbar. It makes the Navbar fixed at the top, 
   stretches it to full width, removes the background, and adds a white border. */
   .custom-navbar {
    position: absolute;
    width: 100%;
    height: 20px;
    background: none !important;
    z-index: 999;
    display: flex;
    align-items: flex-start;
    padding-right: 5%;
    z-index: 1099;
}


/* This section styles the links in the Navbar. It changes the text color to white, 
   removes the underline, and sets the font family. */
.custom-navbar .nav-link {
    color: #ffffff !important;
    text-decoration: none;
    font-family: 'IsidoraSansAlt';
    padding-top: 20%;
}

/* This section styles the links in the Navbar when they are hovered over or focused. 
   It changes the text color to blue. */
.custom-navbar .nav-link:hover,
.custom-navbar .nav-link:focus {
    color: rgb(54, 83, 224) !important;
}

/* This section styles the hamburger menu (custom button). 
   It changes the border color to white to make the icon visible against the transparent background. */
.custom-toggler {
    position: absolute;
    padding-top: 10%;
    right: 10px;
    border: none;
    background: none;
    cursor: pointer;
    z-index: 1001;
    padding-right: 5%;
}

.custom-toggler div {
    width: 25px;
    height: 2.5px;
    background-color: hsl(0, 0%, 100%);
    border-radius: 2px;
    margin: 6px 0;
    transition: .5s;
}

.custom-toggler:focus {
    outline: none;
    box-shadow: none;
}

.custom-toggler.open div:nth-child(1) {
    transform: translateY(9px) rotate(-45deg);
}
.custom-toggler.open div:nth-child(2) {
    opacity: 0;
}
.custom-toggler.open div:nth-child(3) {
    transform: translateY(-7.5px) rotate(45deg);
}

/* This section styles the mobile-nav. */
.mobile-nav {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    transform: translateX(100%);
    transition: transform .7s ease-out;
    background: hsl(0, 0%, 15%);
    width: 100%;
    max-width: 300px;
    padding: 20px;
    overflow: auto;
    z-index: 1099;
}

.mobile-nav.open {
    transform: translateX(0);
}

/* Style the navlinks */
.mobile-nav .nav-link {
    color: #ffffff;
    font-size: 20px;
    margin-top: 20%;
}

.mobile-nav-overlay {
    opacity: 0;
    visibility: hidden;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.9);
    overflow-x: hidden;
    transition: opacity .7s, visibility .7s;
    will-change: transform;
    transform: translateZ(0);
}

.mobile-nav-overlay.open {
    opacity: 1;
    visibility: visible;
}

.overlay-content {
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -100px;
    width: 100%;
    text-align: center;
    margin-top: 30px;
    transition: opacity .7s, top .7s;
}

.mobile-nav-overlay.open .overlay-content {
    top: 25%;
    opacity: 1;
}

.overlay-content .nav-link {
    padding: 8px;
    text-decoration: none;
    font-size: 36px;
    display: block;
    transition: 0.3s;
}

.overlay-content .nav-link:hover {
    color: #f1f1f1;
}

