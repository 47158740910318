/* === GENERAL STYLES === */
.container {
    position: relative;
    display: flex;
    align-items: center;
    color: white;
    height: 100vh;
    z-index: 2;
}

.title-container {
    position: relative;
}

.main-content{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
}

/* === TITLE STYLES === */
.Title {
    font-size: 3.2rem;
    position: relative;
    font-family: 'IsidoraSansAlt';
}

.Title-Desc {
    font-family: 'AlbraBook-Light';
    font-size: 1.1rem;
    margin-inline-end: 15%;
}

/* === VERTICAL TEXT STYLES === */
.Welcoming-verticalText-side {
    writing-mode: vertical-rl;
    position: relative; 
    font-size: 150px; 
    color: rgba(255, 255, 255, 0.15);
    font-family: 'AlbraBlack';   
    white-space: nowrap;
    transform: rotate(180deg);
    left: 10%;
}
  
 /* === HORIZONTAL TEXT STYLES === */
  .Welcoming-verticalText-top{
    position: absolute;
    top: -50px;
    left: 0;
    font-size: 5rem; 
    color: rgba(255, 255, 255, 0.15);
    font-family: 'AlbraBlack'; 
    z-index: -1;  
    white-space: nowrap;
  }


/* === SOCIAL ICON STYLES === */
.social-icon {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    margin-bottom: 20px;
    transition: ease-in-out .2s;
    fill: white;
}

.social-icon:hover {
    transform: scale(1.20);
    fill: rgb(54, 83, 224);
}

/* === BUTTON STYLES === */
.buttonContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-left: 1px solid #ffffff;
}

.tabs {
    background: transparent;
    border: none;
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-family: 'IsidoraSansAlt', 'IsidoraSansAlt-Light';
    font-weight: 600;
    transition: ease-in-out .2s;
    margin-left: 10px;
}

.tabs .arrow path {
    fill: rgb(255, 255, 255);
    transition: ease-in-out .2s;
}

.tabs .arrow {
    width: 24px;
    height: 24px;
    vertical-align: center;
    margin-bottom: 2px;
    transition: transform .2s ease-in-out;
}

.tabs:hover .arrow {
    transform: translateX(10px);
}

.tabs:hover {
    color: rgb(54, 83, 224);
}

.tabs:hover .arrow path {
    fill: rgb(54, 83, 224);
    transition: ease-in-out .2s;
}

/* === CLICKED STYLES === */
.clicked {
    animation: pulse .6s ease-in-out;
    fill: currentColor;  /* SVG will inherit this color */
}

.tabs.clicked {
    animation: pulse .6s ease-in-out;
    color: currentColor;
}

.arrow.clicked path {
    animation: pulse .6s ease-in-out;
    fill: currentColor;
}

.button-text.clicked {
    animation: pulse .6s ease-in-out;
    color: currentColor;
}

@keyframes pulse {
    0% {
      color: rgb(54, 83, 224);
      fill: rgb(54, 83, 224);
    }
    50% {
      color: rgb(22, 209, 237);
      fill: rgb(22, 209, 237);
    }
    100% {
        color: rgb(54, 83, 224);
        fill: rgb(54, 83, 224);
    }
  }
  
.clicked {
    animation: pulse .6s ease-in-out;
    fill: currentColor;
}