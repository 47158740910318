 /* Background */
 .about-background {
    /* Grid */

    min-height: 100vh;
    z-index: 0;
}

.about-container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    z-index: 2;
    margin-left: 40px;
    margin-top: 20vh;
    margin-bottom: 20vh;
}
  
.about-hello-container {
    align-self: center;
    z-index: 1;
}

/* 'Hello I'm' for small screens (overlapped)*/
.about-hello-container.position-absolute {
    z-index: 1;
    top: -40px;
    left: -20px; 
    font-size: 3.75rem;
    white-space: nowrap;
}

/* 'Hello I'm' for larger screens (no overlap)*/
.about-hello-container.d-none {
    position: fixed;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    writing-mode: vertical-rl;
    font-size: min(max(7rem, 10vw), 9rem);
    white-space: nowrap;
}
  
.about-hello {
    color: rgba(255, 255, 255, 0.15);
    font-family: 'AlbraBlack';
    text-align: start;
}
  
.about-main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
}

/* General Info */


.about-Title {
    font-size: 3.2rem;
    font-family: 'IsidoraSansAlt';
}  

.about-Title-Desc {
    font-family: 'AlbraBook-Light';
    font-size: 1.1rem;
    margin-inline-end: 20%;
    border-radius: 15px;
    padding: 10px;
    border: 2px dashed rgba(255, 255, 255, 0.5);
}

.about-buttonContainer{
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-left: 1px solid #ffffff;
}