/* WebKit browsers (Chrome, Safari, newer versions of Edge) */
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-thumb {
    background-color: #888;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

::-webkit-scrollbar-track {
    background-color: #dedede;
}

/* Firefox */
html {
    scrollbar-color: #888 #f1f1f1;
}