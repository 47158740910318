body {
  overflow-x: hidden;
}

.ProjectsPage{
  z-index: 999;
}

.TitleProject {
  font-size: 4rem;
  font-family: 'IsidoraSansAlt';
  color: white;
  margin-top: 20vh;
  text-align: center;
}

.project-verticalText-side {
  position: fixed;
  left: 10%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  font-size: 150px;  
  z-index: -1;  
  white-space: nowrap;
  color: rgba(255, 255, 255, 0.2);
  font-family: 'AlbraBlack';
}

.project-verticalText-top {
  position: absolute;
  top: 16vh;
  left: 0px;
  font-size: 5rem; 
  z-index: -1;  
  white-space: nowrap;
  text-align: center;
  color: rgba(255, 255, 255, 0.15);
  font-family: 'AlbraBlack';
}

.row {
  max-width: 800px;
}

.project-card {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px auto;
}

.project-image {
  position: relative;
  min-height: 210px;
  width: 360px;
  overflow: hidden;
  border-radius: 15px;
}

.project-image video {
  min-height: 210px;
  width: 360px;
  border-radius: 15px;
  object-fit: cover;
  transition: all 0.3s;
  top: 0;
}

.project-info {
  position: absolute;
  bottom: 0;
  color: white;
  background: linear-gradient(0deg, rgba(0,0,0,0.7), rgba(0,0,0,0));
  width: 100%;
  padding: 10px;
  opacity: 0;
  transition: opacity 0.5s;
}

.project-card:hover .project-image img {
  transform: scale(1.1);
}

.project-card:hover .project-info {
  opacity: 1;
}

.project-title {
  font-family: 'IsidoraSansAlt';
  color: white;
  font-size: 2em;
  filter: drop-shadow(5px 5px 10px #000);
  margin-bottom: 4px;
}

.project-description {
  font-family: 'AlbraBook-Light';
  font-size: 0.8em;
  filter: drop-shadow(5px 5px 10px #000);
  margin-bottom: 4px;
}

.tech-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
}

.project-tech {
  font-family: 'IsidoraSansAlt';
  font-size: .75rem;
  filter: drop-shadow(2px 2px 5px #000);
  background: rgba(255, 255, 255, 0.4);
  padding: 1px 8px;
  border-radius: 10px;
  margin-bottom: 4px;
}

@media (max-width: 991.98px) {
  .project-info {
    opacity: 1;
  }
}

.projectOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1099;
}

.ProjectContainer {
  z-index: 1100;
}

.expanded-project {
  position: fixed;
  top: 0;
  right: 0;
  width: 45%;
  height: 100%;
  background: rgb(42, 43, 49);
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: auto;
  animation: slide-in-right 0.4s ease-out forwards;
}

.expanded-project.closing {
  animation: slide-out-right 0.2s ease-out forwards;
}

.expanded-project video {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 35px;
  padding: 20px;
}

.project-contents {
  background-color: rgba(0,0,0,0.2);
  border-radius: 15px;
  margin-top: 1%;
}

.expanded-project h2 {
  margin-top: 5vh;
  font-size: 2em;
  color: #ffffff;
  text-align: center;
  font-family: 'IsidoraSansAlt';
}

.DescriptionContainer{
  margin-bottom: 3em;
  position: relative;
}

.TechnologyContainer{
  margin-bottom: 2em;
  position: relative;
}
.expanded-project h3 {
  font-size: 1.3em;
  text-align: left;
  color: #ffffff;
  text-align: start;
  margin-inline-start: 5%;
  font-family: 'IsidoraSansAlt';
}

.expanded-project p {
  font-size: 1em;
  color: #ffffff;
  margin-inline-start: 5%;
  margin-inline-end: 5%;
  margin-top: -.2em;
}

.CSVdownload{
  position: relative;
  top: 10px;
  transition: ease-in-out .35s;
}

.CSVdownload:hover{
  background-color: rgb(187, 187, 187);
}

.projectTechnologiesExt {
  margin-inline-start: 5%;
  margin-inline-end: 5%;
  margin-bottom: 1.5em;
}

.expanded-project .project-tech, .CSVdownload {
  font-size: 1em;
  color: #494949;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  background-color: #eee;
  padding: 0.4em;
  border-radius: 0.3em;
  font-family: 'IsidoraSansAlt';
  text-decoration: none;
}

.expanded-project h4 {
  font-size: 1em;
  text-align: center;
  color: #ffffff;
  text-align: start;
  margin-inline-start: 5%;
  font-family: 'IsidoraSansAlt';
  width: 100%;
  height: 25px;
  align-self: center;
  transition: color .2s ease-in-out;
  margin-bottom: 10px;
}

.projectSourceLinksContainer {
  background-color: rgba(0,0,0,0.2);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding-top: 20px;
  padding-bottom: 10px;
  position: relative;
}

.projectLinkedGithub, .projectLinkedLiveDemo {
  text-decoration: none;
}

.linkContainerGithub, .linkContainerLiveDemo {
  display: flex;
  align-items: center; 
  justify-content: space-between;
  margin-top: 8px;
}

.linkContainerGithub h4, .linkContainerLiveDemo h4 {
  display: flex;
  align-items: center;
  line-height: 1.2em;
}

.git-icon {
  width: 30px;
  height: 20px;
  margin-bottom: 10px;
  fill: white;
}

.git-icon, .link-out {
  width: 2em;
  height: 1.2em;
  margin-bottom: 0px;
  transition: ease-in-out .2s;
}

.link-out {
  stroke: #ffffff;
}

.linkContainerGithub:hover h4, .linkContainerLiveDemo:hover h4 {
  color: rgb(54, 83, 224);
}

.linkContainerGithub:hover, .linkContainerGithub:hover .git-icon {
  fill: rgb(54, 83, 224);
}

.linkContainerGithub:hover, .linkContainerLiveDemo:hover .link-out {
  stroke: rgb(54, 83, 224);
}

.close-button {
  position: relative;
  text-align: end;
  max-width: 130px;
  top: 0%;
  background-color: rgba(0,0,0,0.2);
  border: none;
  font-size: 1.2em;
  cursor: pointer;
  color: white;
  fill: white;
  transition: ease-in-out .2s;
  text-decoration: none;
  font-family: 'IsidoraSansAlt';
  font-weight: 600;
  padding-bottom: 7px;
  padding-left: 15px;
  padding-top: 5px;
  border-radius: 10px;
  margin-bottom: 10px;
  white-space: nowrap;
  z-index: 1101;
}

.close-button:hover {
  color: rgb(54, 83, 224);
  fill: rgb(54, 83, 224);
}

@keyframes slide-in-right {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide-out-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

.close-button {
  align-self: flex-end;
}
